import { default as _91_46_46_46cms_93HUebTAOuZIMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93pR6xXWMJ4OMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/blog/[...slug].vue?macro=true";
import { default as indexmZkvuvqs6MMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93OGllqg18BUMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removeIAc49EHrDGMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93XTKYL2hzXsMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/product/[...slug].vue?macro=true";
import { default as index8WaOSR2vnnMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93oUmiYALE7MMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/realty/[...slug].vue?macro=true";
import { default as indexNDIcXu9EgZMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/sitemap/index.vue?macro=true";
import { default as indexvCLb2qQVyTMeta } from "/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93HUebTAOuZIMeta?.name ?? "cms",
    path: _91_46_46_46cms_93HUebTAOuZIMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93HUebTAOuZIMeta || {},
    alias: _91_46_46_46cms_93HUebTAOuZIMeta?.alias || [],
    redirect: _91_46_46_46cms_93HUebTAOuZIMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93pR6xXWMJ4OMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93pR6xXWMJ4OMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93pR6xXWMJ4OMeta || {},
    alias: _91_46_46_46slug_93pR6xXWMJ4OMeta?.alias || [],
    redirect: _91_46_46_46slug_93pR6xXWMJ4OMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmZkvuvqs6MMeta?.name ?? "blog",
    path: indexmZkvuvqs6MMeta?.path ?? "/blog",
    meta: indexmZkvuvqs6MMeta || {},
    alias: indexmZkvuvqs6MMeta?.alias || [],
    redirect: indexmZkvuvqs6MMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OGllqg18BUMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93OGllqg18BUMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93OGllqg18BUMeta || {},
    alias: _91_46_46_46slug_93OGllqg18BUMeta?.alias || [],
    redirect: _91_46_46_46slug_93OGllqg18BUMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removeIAc49EHrDGMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removeIAc49EHrDGMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removeIAc49EHrDGMeta || {},
    alias: temp_catalog_need_32_removeIAc49EHrDGMeta?.alias || [],
    redirect: temp_catalog_need_32_removeIAc49EHrDGMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XTKYL2hzXsMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93XTKYL2hzXsMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93XTKYL2hzXsMeta || {},
    alias: _91_46_46_46slug_93XTKYL2hzXsMeta?.alias || [],
    redirect: _91_46_46_46slug_93XTKYL2hzXsMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index8WaOSR2vnnMeta?.name ?? "product",
    path: index8WaOSR2vnnMeta?.path ?? "/product",
    meta: index8WaOSR2vnnMeta || {},
    alias: index8WaOSR2vnnMeta?.alias || [],
    redirect: index8WaOSR2vnnMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93oUmiYALE7MMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93oUmiYALE7MMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93oUmiYALE7MMeta || {},
    alias: _91_46_46_46slug_93oUmiYALE7MMeta?.alias || [],
    redirect: _91_46_46_46slug_93oUmiYALE7MMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexNDIcXu9EgZMeta?.name ?? "sitemap",
    path: indexNDIcXu9EgZMeta?.path ?? "/sitemap",
    meta: indexNDIcXu9EgZMeta || {},
    alias: indexNDIcXu9EgZMeta?.alias || [],
    redirect: indexNDIcXu9EgZMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexvCLb2qQVyTMeta?.name ?? "wish",
    path: indexvCLb2qQVyTMeta?.path ?? "/wish",
    meta: indexvCLb2qQVyTMeta || {},
    alias: indexvCLb2qQVyTMeta?.alias || [],
    redirect: indexvCLb2qQVyTMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241210065445/pages/wish/index.vue").then(m => m.default || m)
  }
]